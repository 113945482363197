<template>
	<div class="form">
		<div>
			<StockItemForm formsType="Add" />
		</div>
	</div>
</template>

<script>
import { computed, onMounted } from '@vue/composition-api'
import StockItemForm from '@/_srcv2/pages/stock-ours/_shared/StockItemForm.vue'
import stockItemStore from '@/_srcv2/pages/stock-ours/store-stock/useStockItemState.js'
import Store from '@/store'

export default {
	name: 'AddStockItem',
	components: {
		StockItemForm,
	},
	setup() {
		const ourCompany = computed(() => Store.getters.getUserCurrentCompany)
		const {
			modelStock,
			schemaStock,
			// selectedStockItemId
		} = stockItemStore()
		schemaStock.groups[0].fields[1].disabled = false
		// ! ---------------------------------------------------------------------
		const cleanTheForm = () => {
			modelStock.stockId = ''
			modelStock.stockName = ''
			modelStock.stockUnit = ''
			modelStock.stockPrice = 0
			modelStock.stockPriceA = 0
			modelStock.stockPriceB = 0
			modelStock.stockPriceC = 0
			modelStock.stockPriceD = 0
			modelStock.stockPriceS = 0
			modelStock.stockPriceZ = 0
			modelStock.stockPriceHRA = 0
			modelStock.stockPriceHRB = 0
			modelStock.stockPriceHRC = 0
			modelStock.stockPriceHRD = 0
			modelStock.stockMoms = ''
			modelStock.stockGroup = ''
			modelStock.stockAlarm = 0
			modelStock.stockShelf = ''
			modelStock.companyId = Store.getters.getUserCurrentCompany
			modelStock.stockIsActive = true
			modelStock.campaignPrice = 0
			modelStock.isCampaignActive = false
		}
		onMounted(() => cleanTheForm())
		return { ourCompany }
	},
}
</script>

<style scoped>
.form {
	width: 450px;
	margin: auto;
	padding-top: 30px;
}
</style>
