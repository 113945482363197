<template>
	<layout-wide>
		<div>
			<sub-navbar page="Stock" />
		</div>
		<div>
			<SearchStockItems />
		</div>
	</layout-wide>
</template>

<script>
import SearchStockItems from '@/_srcv2/pages/admin/edit-stock-prices/sub-components/SearchStockItems.vue'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
export default {
	name: 'AddEditStockPrices',
	components: {
		SearchStockItems,
		SubNavbar,
	},
	setup() {
		//-------------------------------------------------------------------
		return {}
	},
}
</script>

<style scoped></style>
